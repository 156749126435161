import FetchThenExecute from './utils/fetchThenExecute';
import debounce from './utils/debounce';
import SliderYoutubeController from './components/SliderYoutubeController';
import $ from "jquery";

$(document).on('ready', () => {
  fetchSliders();
  handleWindowResizeForProductThumbsSlider();
})

let initSliders;

function fetchSliders() {
  initSliders = new FetchThenExecute({
    selector: '.theme-slider',
    files: () => ([
      import('../css/components/slick-theme.scss'),
      import('../css/components/slick.scss'),
      import('./lib/slick.min.js')
    ]),
    fnc: initSlider
  })

  prestashop.on('updatedProduct', () => {
    initSliders.update();
  });

  $(document).on('shown.bs.modal', '#blockcart-modal', (event) => {
    console.log('im here');
    initSliders.update();
  });
}

function initSlider(el) {
  const $elem = $(el);

  $elem.on('init', function() {
    $elem.addClass('slick-init');
  })

  if($elem.hasClass('products')) {
    initProductsSlider($elem);
  } else if($elem.hasClass('homeSlider__list')) {
    initHomeSlider($elem)
  } else if($elem.hasClass('productThumbs')) {
    initProductThumbsSlider($elem)
  } else if($elem.hasClass('js-modal-product-images')) {
    initModalImgSlider($elem)
  }
}


function initProductsSlider($el) {
  console.log(prestashop.responsive.mobile);
  if($el.attr('id') === 'lookbook' && prestashop.responsive.mobile) {
    console.log($el.attr('id'));
    $el.slick({
      dots: false,
      infinite: false,
      speed: 600,
      autoplay: false,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      slidesPerRow: 2,
      rows: 2,
      lazyLoad: 'ondemand',
      responsive: [
        {
          breakpoint: 1425,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesPerRow: 2,
            rows: 2
          }
        }
      ]
    });
  } else {
    $el.slick({
      dots: false,
      infinite: false,
      speed: 600,
      autoplay: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      lazyLoad: 'ondemand',
      responsive: [
        {
          breakpoint: 1425,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }

}

function initModalImgSlider($el) {
  $el.slick({
    infinite: false,
    arrows: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1
  });
}

function initHomeSlider($el) {
  const infinite = $el.attr('data-wrap') == 'true';
  const autoplaySpeed = $el.attr('data-interval');
  const pauseOnHover = $el.attr('data-pause') == 'hover';

  $el.slick({
    infinite,
    autoplay: true,
    autoplaySpeed,
    pauseOnHover,
    arrow: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1
  });

    let controller = new SliderYoutubeController($el);
}

function initProductThumbsSlider($el) {

  const slidesToShow = 5;

  const responsive = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
      slidesToShow: 3,
      slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }
  ];

  const $sliderItem = $el.find('.productThumbs__thumb');

  const shouldSliderInit = shouldProductThumbsSliderInit($sliderItem, slidesToShow, responsive);

  const isSliderActive = $el.hasClass('slick-slider');


  if(shouldSliderInit && !isSliderActive) {
    $el.slick({
      infinite: false,
      arrows: false,
      speed: 600,
      autoplay: false,
      slidesToShow,
      slidesToScroll: 1,
      responsive
    });
    $el.slick('setPosition');
  } else if (!shouldSliderInit && isSliderActive) {
    $el.slick('unslick');
    $el.addClass('slick-init');
  } else if(!isSliderActive && !shouldSliderInit) {
    $el.addClass('slick-init');
  }

    $(document).on('click', '.productThumbs__thumb.slick-active:last', function (e){
        let $this = $(this);
        if($this.next().length && $el.hasClass('slick-initialized')){
            $el.slick('slickNext');
        }
    });

    $(document).on('click', '.productThumbs__thumb.slick-active:first', function (e){
        let $this = $(this);
        if($this.prev().length && $el.hasClass('slick-initialized')){
            $el.slick('slickPrev');
        }
    });

    $el.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.productThumbs__thumb').addClass('productThumbs--no-events');
    });

    $el.on('afterChange', function(event, slick, currentSlide){
        $('.productThumbs__thumb').removeClass('productThumbs--no-events');
    });
}

function handleWindowResizeForProductThumbsSlider() {
  const handler = debounce(() => {
    if(initSliders && initSliders.libraryLoaded) {
      initProductThumbsSlider($('.productThumbs'));
    }
  }, 200);

  $(window).on('resize', handler)
}


function shouldProductThumbsSliderInit($el, slidesToShow, responsive) {
  const windowWidth = $(window).innerWidth();
  const elLength = $el.length;
  let shouldRun = false;

  for(const i in responsive) {
    const bp = responsive[i];
    const nextBp = responsive[+i + 1];

    if(i == 0 && windowWidth > bp.breakpoint && elLength > slidesToShow) { // FOR FIRST ITERATION WE HAVE TO CHECK BREAKPOINT UP FIRST ELEMENT
      shouldRun = true;
      break;
    }

    if(typeof nextBp !== 'undefined') {
      if(windowWidth <= bp.breakpoint && windowWidth > nextBp.breakpoint && elLength > bp.settings.slidesToShow) {
        shouldRun = true;
        break;
      }
    } else {
      if(windowWidth <= bp.breakpoint && elLength > bp.settings.slidesToShow) {
        shouldRun = true;
        break;
      }
    }


  }

  return shouldRun;
}
