
import $ from 'jquery';
import '../lib/jquery.cookie.js';

export default class CookieBar {
  constructor({
    selector = '.cookiebar',
    cookieName ='cookiebar',
    closeSelector = '.js-close-cookiebar'
  }) {
    var $cookieContainer = $(selector);
    this.name = cookieName;
    this.$cookieContainer = $cookieContainer;
    this.closeSelector = closeSelector;
    this.expiration = parseInt($cookieContainer.attr('data-expiration'), 10);
    this.init();
  }

  cookieExists() {
    return $.cookie(this.name);
  }

  setCookie() {
    $.cookie(this.name, 1, { 
      expires: this.expiration 
    });
  }

  setEvents() {
    this.$cookieContainer.find(this.closeSelector).on('click', e => {
      e.preventDefault();
      this.setCookie();
      this.hideCookieBar();
    })
  }

  showCookieBar() {
    this.$cookieContainer.show();
  }

  hideCookieBar() {
    this.$cookieContainer.fadeOut();
  }
  

  init() {
    if(!this.cookieExists()) {
      this.setEvents();
      this.showCookieBar();
    }
  }

}