export default class SecondaryDropdown {
  constructor({selector, onClose = () => {}, onOpen = () => {}}) {
    this.selector = selector;
    this.$body = $('body');
    this.onClose = onClose;
    this.onOpen = onOpen;
    this.init()
  }


  init() {
    const self = this;

    this.$body.on('click', ({target}) => {
      const $dropdown = $(this.selector);
      const $parent = $dropdown.parent();
      const $target = $(target);
      const isOpen = $parent.closest('.open.dropdown').length > 0;


      if (($parent.has(target).length === 0 && isOpen)
          || (($target.is($dropdown) || $target.closest(this.selector).length > 0)  && isOpen)
      ) {
        $parent.removeClass('open');
        this.onClose();
        this.$body.removeClass('secondary-dropdown-open');
      } else if(!isOpen && ($target.is($dropdown) || $target.closest(this.selector).length)) {
        const $parentDropdown = $target.closest('.dropdown');
        $parentDropdown.toggleClass('open');
        this.onOpen();
        this.$body.addClass('secondary-dropdown-open');
      }
    });
  
  }


}