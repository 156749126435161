import $ from 'jquery';

export default class CmsNavigationLookbook {
    init() {
        this.addClickEvents();
    }

    addClickEvents() {
        const lookbookTabItemSelector = '.js-cms-category-lookbook-tab-item';

        $(document).on('click', '.js-cms-category-lookbook-next', (e) => {
            e.preventDefault();
            const lookbookTabActive = $('.js-cms-category-lookbook-tab.active');
            const lookbookTabActiveParent = lookbookTabActive.parents(lookbookTabItemSelector);
            lookbookTabActiveParent
                .next(lookbookTabItemSelector)
                .find('.js-cms-category-lookbook-tab')
                .tab('show')
            ;
            this.scrollAfterClickNavigate();
        });

        $(document).on('click', '.js-cms-category-lookbook-prev', (e) => {
            e.preventDefault();
            const lookbookTabActive = $('.js-cms-category-lookbook-tab.active');
            const lookbookTabActiveParent = lookbookTabActive.parents(lookbookTabItemSelector);
            lookbookTabActiveParent
                .prev('.js-cms-category-lookbook-tab-item')
                .find('.js-cms-category-lookbook-tab')
                .tab('show')
            ;
            this.scrollAfterClickNavigate();
        });
    }

    scrollAfterClickNavigate() {
        const headerHeight = $('#header').height();
        const topOffset = headerHeight;
        const elementToScroll = $('.js-category-lookbook-tab-content-wrapper');

        $('html, body').animate({
            scrollTop: elementToScroll.offset().top - topOffset
        }, 1000);
    }
}
