export default class Sort {
  constructor() {
    this.$body = $('body');
    this.selector = '[name="sort-by"]';
    this.oldSortUrl = null;
    this.sortUrl = null;
    this.init();
  }

  setUrl() {
    const url = $(this.selector + ':checked').attr('data-href');
    this.oldSortUrl = url;
    this.sortUrl = url;
  }

  setEvent() {
    this.$body.on('change', this.selector, ({target}) => {
      const $target = $(target);

      if($target.prop('checked')) {
        this.sortUrl = $target.attr('data-href');
      }
    })

    this.$body.on('click', '[data-action="submit-sort"]', (e) => {
      e.preventDefault();
      this.submitSort(true);
    })
  }

  submitSort(force) {
    if(force || this.oldSortUrl != this.sortUrl) {
      prestashop.emit('updateFacets', this.sortUrl);
      this.oldSortUrl = this.sortUrl;
    }
  }

  init() {
    this.setUrl();
    this.setEvent();
  }

}