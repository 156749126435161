import $ from 'jquery';
import YouTubePlayer from './YouTubePlayer';
import YoutubeAPI from './youtube-api';

export default class SliderYoutubeController {
    /**
     * @param {jQuery} $slider
     */
    constructor($slider) {
        if (YoutubeAPI.isReady()) {
            this.constructorDeferred($slider);
        } else {
            $(document).on('youtube-api-initialized', () => {
                this.constructorDeferred($slider);
            });
        }
    }

    /**
     * @param {jQuery} $slider
     */
    constructorDeferred($slider) {
        this.$slider = $slider;
        this.getAllSlides().each((i, current) => {
            $(current).attr('data-index', i);
        });
        this.$slider.on('beforeChange', (ev, slick, prevSlide, nextSlide) => {
            this.changeAction(ev, slick, prevSlide, nextSlide);
        });
        this.changeAction(
            new Event('dummy-event'),
            this.$slider.slick('getSlick'),
            this.getCurrentSlide(),
            this.getCurrentSlide()
        );
    }



    /**
     * @param {Event} event
     * @param {object} slider
     * @param {Number} prevSlideIndex - prev slide index
     * @param {Number} nextSlideIndex - next slide index
     */
    changeAction(event, slider, prevSlideIndex, nextSlideIndex) {
        this.destroyAll();
        this.constructPlayer(nextSlideIndex);
    }


    /**
     * @param {Number} i
     */
    getSlideByIndex(i) {
        return this.$slider.find(`.slick-slide[data-index="${i}"]`);
    }

    getAllSlides() {
        return this.$slider.find('.slick-slide').not('.slick-cloned');
    }

    getCurrentSlide() {
        return this.$slider.slick('slickCurrentSlide');
    }
    /**
     * @param {null|Number} i - if null then current is taken
     */
    getPlayerData(i = null) {
        if (i === null) {
            i = this.getCurrentSlide();
        }
        return this.getSlideByIndex(i).find('[data-youtube]');
    }

    destroyAll() {
        this.$slider.find('[data-youtube]').each((i, thatPlayer) => {
            // destroy somehow
            this.destroyPlayer($(thatPlayer));
        });
    }

    /*
     * @param {jQuery} thatPlayer
     */
    destroyPlayer(thatPlayer) {
        thatPlayer.parent().find('iframe').remove();
    }

    /**
     * @param {Number} nextSlideIndex
     */
    constructPlayer(nextSlideIndex) {
        let $playerDataElement = this.getPlayerData(nextSlideIndex);
        if (!$playerDataElement.length) {
            return;
        }
        let player = new YouTubePlayer($playerDataElement);
        player.enable();
        $playerDataElement.parent().find('iframe').addClass('homeSlider__yt-iframe');
    }
}
