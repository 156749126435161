import getQueryParameters from './urlparser';
import NumberFormatter from '../cldr/number-formatter';



export default class SearchFilters {
  constructor() {
    this.baseUrl = window.location.origin + window.location.pathname;
    this.oldSearchUrl = null;
    this.searchUrl = null;
    this.$body = $('body');
    this.grupQty = {};
    this.formatters = {};
  }


  init() {
    this.setSearchUrl();
    this.setEvents();
    this.setGroupQty();
    this.initSliders();

    prestashop.on('updateProductList', () => {
      this.initSliders();
    })
  }

  setSearchUrl() {
    const searchParams = new URLSearchParams(window.location.search);
    this.searchUrl = searchParams.get('q');
    this.oldSearchUrl = searchParams.get('q');
  }

  setGroupQty() {
    const $qtyGroup = $('.facetSearch__selectedFilters');
    
    $qtyGroup.each((i, el) => {
      const $el = $(el);
      const group = $el.attr('data-group');
      const qty = $el.attr('data-qty');
      
      this.grupQty[group] = +qty;
    })
  }

  updateDOMQtyGroup(group) {
    const $qtyGroup = $(`.facetSearch__selectedFilters[data-group=${group}]`);
    const $qtyEl = $qtyGroup.find('.facetSearch__qty');
    const qty = this.grupQty[group];
    const activeClass = 'facetSearch__selectedFilters--active';

    if(qty > 0) {
      $qtyGroup.addClass(activeClass);
    } else {
      $qtyGroup.removeClass(activeClass);
    }
    $qtyEl.text(qty);
  }


  addQtyToGroup(group) {
    this.grupQty[group] = ++this.grupQty[group];
  }

  removeQtyFromGroup(group) {
    this.grupQty[group] = --this.grupQty[group];
  }

  resetQtyGroup() {
    const groups = {...this.grupQty};
    for(let group in groups) {
      groups[group] = 0
    }

    this.grupQty = groups;
  }
  
  changeSearchUrl(url) {
    return this.searchUrl = url;
  }

  appendParam(group, prop) {
    const oldSearchUrl = this.searchUrl || '';
    let newSearchUrl = oldSearchUrl.split('/');
    let groupExist = false;

    for(let i in newSearchUrl) {
      const filterGroup = newSearchUrl[i];
      const filterGroupArry = filterGroup.split('-');
      if(filterGroupArry[0] == group) {
        newSearchUrl[i] = newSearchUrl[i] + "-" + prop;
        groupExist = true;
        break;
      } 
    }

    if(!groupExist) {
      newSearchUrl = [...newSearchUrl, group + '-' + prop]
    }

    this.addQtyToGroup(group);
    this.searchUrl = this.formatSearchUrl(newSearchUrl);
  }

  removeParam(group, prop) {
    const oldSearchUrl = this.searchUrl || '';
    let newSearchUrl = oldSearchUrl.split('/');

    for(let i in newSearchUrl) {
      const filterGroup = newSearchUrl[i];
      const filterGroupArry = filterGroup.split('-');

      if(filterGroupArry[0] == group) {
        const filterRestul = filterGroupArry.filter(el => el !== prop);
        
        if(filterRestul.length == 1) {
          newSearchUrl.splice(i, 1);
        } else {
          newSearchUrl[i] = filterRestul.join('-');
        }
        break;
      }
    }

    this.removeQtyFromGroup(group);
    this.searchUrl = this.formatSearchUrl(newSearchUrl);
  }

  filterResult(force) {
    if(this.searchUrl !== this.oldSearchUrl || force) {
      this.oldSearchUrl = this.searchUrl;
      prestashop.emit('updateFacets', this.baseUrl + '?q=' + this.searchUrl);
    }
  }

  clearFilters(url) {
    prestashop.emit('updateFacets', url);
    this.searchUrl = null;
    this.resetQtyGroup();
  }

  formatSearchUrl(arry) {
    return arry.join('/');
  }

  setEvents() {
    this.$body.on('change', '#search_filters input[data-search-url]', ({target}) => {
      const $target = $(target);
      const group = $target.attr('data-group');
      const prop = $target.attr('data-prop');

      if($target.prop('checked')) {
        this.appendParam(group, prop);
      } else {
        this.removeParam(group, prop);
      }
      this.updateDOMQtyGroup(group);
    });
  
    this.$body.on('click', '.js-search-filters-clear-all', ({target}) =>  {
      this.clearFilters($(target).attr('data-search-url'));
    });
  
    this.$body.on('click', '.filter_btn', e => {
      e.preventDefault();
      this.filterResult(true);
    });
  }

  initSliders() {
    const self = this;
    $('.faceted-slider').each(function() {
      const $el = $(this);
      const values = $el.data('slider-values');
      const specifications = $el.data('slider-specifications');
      const sliderId = $el.data('slider-id');

      if (specifications !== null && specifications !== undefined) {
        self.formatters[sliderId] = NumberFormatter.build(specifications);
      }
  
      self.refreshSliderLabel(
        sliderId,
        $(`#facet_label_${sliderId}`),
        values === null ? $el.data('slider-min') : values[0],
        values === null ? $el.data('slider-max') : values[1],
      );
  
      $(`#slider-range_${sliderId}`).slider({
        range: true,
        min: $el.data('slider-min'),
        max: $el.data('slider-max'),
        values: [
          values === null ? $el.data('slider-min') : values[0],
          values === null ? $el.data('slider-max') : values[1],
        ],
        stop(event, ui) {
          const nextEncodedFacetsURL = $el.data('slider-encoded-url');
          const urlsSplitted = nextEncodedFacetsURL.split('?');
          let queryParams = [];
  
          // Retrieve parameters if exists
          if (urlsSplitted.length > 1) {
            queryParams = getQueryParameters(urlsSplitted[1]);
          }
  
          let found = false;
          queryParams.forEach((query) => {
            if (query.name === 'q') {
              found = true;
            }
          });
  
          if (!found) {
            queryParams.push({name: 'q', value: ''});
          }
  
          // Update query parameter
          queryParams.forEach((query) => {
            if (query.name === 'q') {
              // eslint-disable-next-line
              query.value += [
                query.value.length > 0 ? '/' : '',
                $el.data('slider-label'),
                '-',
                $el.data('slider-unit'),
                '-',
                ui.values[0],
                '-',
                ui.values[1],
              ].join('');
            }
          });
  
          const params = queryParams[0].value;

          self.changeSearchUrl(params);
        },
        slide(event, ui) {
          self.refreshSliderLabel(
            sliderId,
            $(`#facet_label_${sliderId}`),
            ui.values[0],
            ui.values[1],
          );
        },
      });

    });
  }


  refreshSliderLabel(formatterId, displayBlock, min, max) {
    if (this.formatters[formatterId] === undefined) {
      displayBlock.text(
        displayBlock.text().replace(
          /([^\d]*)(?:[\d .,]+)([^\d]+)(?:[\d .,]+)(.*)/,
          `$1${min}$2${max}$3`,
        ),
      );
    } else {
      displayBlock.text(
        `${this.formatters[formatterId].format(min)} - ${this.formatters[formatterId].format(max)}`,
      );
    }
  }

  emmit() {
    prestashop.emit('updateFacets', this.url);
  }

}